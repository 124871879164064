import Link from 'next/link'
import map from 'lodash/map'
import React, { useCallback } from 'react'
import dayjs from '@/helpers/dayjs'

function NotificationItem({ item, setOpenModal, markAllAsRead }) {
    const getLink = useCallback(() => {
        if (item?.module === 'model') {
            return `/models/${item?.referenceId}`
        }
    }, [item])

    const handleClose = useCallback(() => {
        markAllAsRead([item.id])
        setOpenModal(false)
    }, [item?.id, markAllAsRead, setOpenModal])

    return (
        <Link href={getLink()} className="relative focus-visible:outline-none">
            <div
                onClick={handleClose}
                className={`relative mb-3 flex h-full w-full cursor-pointer items-start gap-4 rounded-md border px-4 py-3 pr-10 ${
                    item?.isRead
                        ? 'border-interface-200 bg-interface-50'
                        : 'border-interface-300 bg-interface-200'
                }`}
            >
                <span
                    className={`absolute right-4 top-3 rotate-[140deg] font-bold`}
                >
                    <i
                        className={`icon-prev text-base ${
                            !item?.isRead
                                ? 'text-primary-500'
                                : 'text-interface-600'
                        }`}
                    ></i>
                </span>
                <div>
                    <p className="mb-1">{item?.data.heading}:</p>
                    <p className="mb-1 text-sm text-interface-700">
                        {item?.data.message}
                        {item?.data?.reasons?.length
                            ? ' Reasons for retraining are:'
                            : ''}
                    </p>
                    {!!item?.data?.reasons?.length && (
                        <ul>
                            {map(item?.data?.reasons, (reason, index) => (
                                <li
                                    key={index}
                                    className="mb-2 ml-6 list-disc text-interface-700 last:mb-1"
                                >
                                    {reason}
                                </li>
                            ))}
                        </ul>
                    )}

                    <p className="mt-1 text-xs text-interface-500">
                        {dayjs(item?.createdAt).format('MMM DD, YYYY hh:mm')}
                    </p>
                </div>
            </div>
        </Link>
    )
}

export default NotificationItem
