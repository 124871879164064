import map from 'lodash/map'
import Link from 'next/link'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'

import styles from '@/styles/Navigation.module.css'

const Navigation = ({
    items,
    tabList,
    click,
    customClass,
    switchTab,
    notificationCount,
}) => {
    const { pathname, query } = useRouter()

    return (
        <div
            className={`${styles.navigation} ${
                tabList ? styles.tabList : ''
            } ${customClass}`}
        >
            <ul className="navigation">
                {map(items, (item, index) => (
                    <li
                        key={index}
                        onClick={click}
                        className={`nav-item ${
                            pathname === item.pathname ||
                            (pathname === item.route?.pathname &&
                                isEqual(query, item.route?.query))
                                ? styles.active
                                : ''
                        }`}
                    >
                        {(item.route || item.pathname) && (
                            <Link
                                key={`${index}`}
                                href={
                                    item?.route
                                        ? item?.route
                                        : {
                                              pathname: item.pathname,
                                              query: item?.route?.query?.action
                                                  ? item?.route?.query
                                                  : {
                                                        ...query,
                                                    },
                                          }
                                }
                                className={`${
                                    item?.disabled
                                        ? 'item-link pointer-events-none'
                                        : 'item-link'
                                } tooltip relative`}
                            >
                                {item.icon && (
                                    <>
                                        <i
                                            className={`item-icon ${item.icon}`}
                                        />
                                        <span className="tooltip-content tooltip-content-bottom -left-18 top-1">
                                            {item.tooltipLabel}
                                        </span>
                                    </>
                                )}
                                <span className="item-label">{item.label}</span>
                            </Link>
                        )}
                        {item.click && (
                            <p
                                className="item-link tooltip relative"
                                onClick={item.click}
                            >
                                {!!item?.showCount && !!notificationCount && (
                                    <span className="notification-count absolute right-0 top-0 z-10 flex h-4 items-center justify-center rounded-lg bg-blue-600 px-[6px] py-[2px] text-xs text-common-white">
                                        {notificationCount < 9
                                            ? notificationCount
                                            : '9+'}
                                    </span>
                                )}
                                {item.icon && (
                                    <>
                                        <i
                                            className={`item-icon ${item.icon}`}
                                        />
                                        <span className="tooltip-content tooltip-content-bottom -left-1 top-1">
                                            {item.tooltipLabel}
                                        </span>
                                    </>
                                )}
                                <span className="item-label">{item.label}</span>
                            </p>
                        )}
                        {item.tabId && (
                            <p
                                id={item.tabId}
                                className={styles.tabItem}
                                onClick={switchTab}
                            >
                                {item.label}
                            </p>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Navigation
