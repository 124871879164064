import { useCallback, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import Loader from '@/components/base/Loader'
import NotificationItem from '@/components/notifications/NotificationItem'
import NoNotificationFound from '@/components/notifications/NoNotificationFound'

import usePagination from '@/data/usePagination'
import { useSession } from '@/contexts/Session'

const NotificationDrawer = ({ setCount, setOpenModal, fetchCount }) => {
    const { authToken } = useSession()

    const {
        data,
        size,
        setSize,
        isReachingEnd,
        isLoadingInitialData,
        isLoadingMore,
        mutate,
    } = usePagination(
        'notification',
        8,
        {
            order: 'DESC',
            orderBy: 'createdAt',
            organizationId: authToken?.organizationId,
        },
        true,
        true
    )

    const markAllAsRead = useCallback(
        async (notificationIds = []) => {
            try {
                const updateURL = `${process.env.NEXT_PUBLIC_API_URL}/notification`
                const fetchOptions = {
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken?.accessToken}`,
                        organizationId: authToken?.organizationId,
                    },
                }
                if (notificationIds?.length) {
                    fetchOptions.body = JSON.stringify({
                        notificationIds,
                    })
                }
                const response = await fetch(updateURL, fetchOptions)
                if (response.ok) {
                    const { message } = await response.json()

                    if (message) {
                        mutate()
                        if (!notificationIds?.length) {
                            setCount(0)
                            setOpenModal(false)
                        } else {
                            fetchCount()
                        }
                    }
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error)
            }
        },
        [
            authToken?.accessToken,
            authToken?.organizationId,
            fetchCount,
            mutate,
            setCount,
            setOpenModal,
        ]
    )

    useEffect(() => {
        mutate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className=" z-s20 relative mt-[-15px] h-screen pl-8 pr-0">
            <div className="mb-6 flex items-center">
                <h2 className="mb-0 text-lg font-bold text-interface-900">
                    Notifications
                </h2>
                {!!data?.length && (
                    <span
                        onClick={markAllAsRead}
                        className="ml-auto cursor-pointer text-sm font-bold text-primary-500 underline"
                    >
                        Mark all as read
                    </span>
                )}
            </div>
            {!!data?.length && (
                <div
                    id="scrollableDiv"
                    // className="scrollable mr-[-24px] !h-[calc(100vh-85px)] pr-0"
                >
                    <InfiniteScroll
                        dataLength={data?.length || 0}
                        next={() => {
                            setSize(size + 1)
                        }}
                        hasMore={!isReachingEnd}
                        scrollableTarget="scrollableDiv"
                        height="calc(100vh - 85px)"
                        className="mr-[-24px] pr-0"
                        loader={
                            !!isLoadingMore && (
                                <div className="flex flex-col items-center justify-center pb-4 text-center text-sm text-interface-500">
                                    <Loader loaderContainer="py-5" />
                                </div>
                            )
                        }
                    >
                        <div className="pr-6">
                            {data?.map((item, index) => {
                                return (
                                    <NotificationItem
                                        key={index}
                                        item={item}
                                        setOpenModal={setOpenModal}
                                        markAllAsRead={markAllAsRead}
                                    />
                                )
                            })}
                        </div>
                    </InfiniteScroll>
                </div>
            )}
            {!data?.length && !!isLoadingInitialData && (
                <div className="flex h-[calc(100vh-80px)] flex-col items-center justify-center pb-4 text-center text-sm text-interface-500">
                    <Loader loaderContainer="py-5" />
                </div>
            )}
            {!data?.length && !isLoadingInitialData && (
                <div className="flex h-[calc(100vh-80px)] items-center justify-center text-center">
                    <NoNotificationFound />
                </div>
            )}
        </div>
    )
}

export default NotificationDrawer
