import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Header from '@/components/base/Header'
import Sidebar from '@/components/base/Sidebar'
// import { Menu, Transition } from '@headlessui/react'
// import Button from '@/components/base/Button'
// import Separator from '@/components/base/Separator'

import styles from '@/styles/Sidebar.module.css'
import { useSession } from '@/contexts/Session'

const Main = ({ children }) => {
    const { pathname } = useRouter()
    const { authToken, setAuthToken } = useSession()

    const isMenuOpen = authToken?.isMenuOpen
        ? JSON.parse(authToken?.isMenuOpen)
        : false

    const [isDivVisible, setDivVisible] = useState(isMenuOpen)

    // Step 3: Implement the function to toggle the class.
    const toggleSidebar = () => {
        // setAuthToken('isMenuOpen', !isMenuOpen, {
        //     path: '/',
        //     sameSite: true,
        //     domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        // })
        setDivVisible((prevVisible) => !prevVisible)
    }
    const toggleTopNav = () => {
        if (isDivVisible) {
            setDivVisible(true)
        }
        setAuthToken('isMenuOpen', !isMenuOpen, {
            path: '/',
            sameSite: true,
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        })
    }

    return (
        <div>
            <span
                onClick={toggleSidebar.bind(null, 'menu-icon')}
                className="tooltip fixed left-4 top-4 z-30 cursor-pointer"
            >
                <i
                    className={`text-xl text-common-white ${
                        isDivVisible ? 'icon-menu-pinned' : 'icon-menu'
                    }`}
                />
                <span className="tooltip-content tooltip-content-bottom -left-4 top-[1px]">
                    {isDivVisible ? 'Collapse' : 'Expand'}
                </span>
            </span>
            <div>
                {/* mobile nav icon */}
                <div className="menu-toggle">
                    <i className="icon-nav-menu" onClick={toggleTopNav}></i>
                </div>
                {/* header */}
                <Header isMenuOpen={isMenuOpen} />
            </div>

            {/* sidebar */}
            <Sidebar
                className={`${
                    isDivVisible
                        ? `animateMenuItems nav-lg-screens`
                        : `${styles.sidebarSmall} small-sidebar`
                }`}
            />
            {/* rightside area */}
            <div
                className={`ml-auto flex ${styles.rightsideArea} ${
                    isDivVisible
                        ? `w-[calc(100%-240px)] ${styles.animateToggle} mobile:!w-full`
                        : `w-[calc(100%-56px)] ${styles.animateToggle} mobile:!w-full`
                }`}
            >
                <div
                    className={`right-sidearea relative ${
                        pathname.includes('/eco-pilot') ? '!p-0' : ''
                    } ${isDivVisible ? 'sidebar-expanded' : ''}`}
                >
                    {children}
                    {/* {!['/eco-pilot'].includes(pathname) && (
                        <div
                            className={`fixed bottom-8 ${
                                [
                                    '/models',
                                    '/management/organization',
                                    '/management/organization/[action]/[[...id]]',
                                ].includes(pathname)
                                    ? 'left-10'
                                    : 'right-10'
                            }`}
                        >
                            <Menu as="div">
                                <Menu.Button className="border rounded-full border-primary-600 bg-primary-500 shadow-thumnails hover:border-primary-600 hover:bg-primary-600">
                                    <span className="flex h-[60px] w-[60px] items-center justify-center text-common-white ">
                                        <i className="icon-chatbot text-[26px]" />
                                    </span>
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-30"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className={`dropdown-menu chat-dropdown-menu link-dropdown-menu ${
                                            [
                                                '/models',
                                                '/management/organization',
                                                '/management/organization/[action]/[[...id]]',
                                            ].includes(pathname)
                                                ? 'left-0'
                                                : 'right-0'
                                        }`}
                                    >
                                        <Menu.Item>
                                            {({ close }) => (
                                                <Button
                                                    variant="dropdown-menu"
                                                    type="button"
                                                    icon="add-thin"
                                                    text="Start a New Chat"
                                                    click={() => {
                                                        close()
                                                    }}
                                                    link="/eco-pilot?newChat=true"
                                                    modification="text-common-white !font-light hover:bg-primary-700 overflow-hidden"
                                                />
                                            )}
                                        </Menu.Item>
                                        <Separator modification="bg-primary-700 !my-2" />
                                        <Menu.Item>
                                            {({ close }) => (
                                                <Button
                                                    variant="dropdown-menu"
                                                    type="button"
                                                    icon="log"
                                                    text="View Chat History"
                                                    click={() => {
                                                        close()
                                                    }}
                                                    link="/eco-pilot"
                                                    modification="text-common-white !font-light hover:bg-primary-700"
                                                />
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    )} */}
                </div>
            </div>
        </div>
    )
}

export default Main
