/* eslint-disable */
import { useState } from 'react'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import isUndefined from 'lodash/isUndefined'

import axios from '@/helpers/axios'

const getKey = (pageIndex, previousPageData, url, pageSize) => {
    if (previousPageData && !previousPageData?.length) {
        return null
    }

    if (url) {
        if (!pageIndex) {
            return `${url}?paginate=true&&limit=${pageSize}`
        }

        return `${url}?paginate=true&page=${pageIndex + 1}&limit=${pageSize}`
    }

    return null
}

const fetcher = async (url, params, setCount) => {
    const {
        data: { data, count },
    } = await axios.get(url, { params })
    if (count) {
        setCount(count)
    } else {
        setCount(0)
    }
    return data
}

export default function usePagination(
    url,
    pageSize = 10,
    params = {},
    revalidateFirstPage = true,
    infiniteScroll = true
) {
    const [count, setCount] = useState(0)

    let { data, error, size, setSize, isValidating, mutate } = {}

    if (infiniteScroll) {
        ;({ data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
            (...args) => [getKey(...args, url, pageSize), params],
            (...args) => fetcher(...args, setCount),
            {
                revalidateFirstPage,
            }
        ))
    } else {
        ;({ data, error, size, setSize, isValidating, mutate } = useSWR(
            (...args) => [getKey(null, null, url, pageSize), params],
            (...args) => fetcher(...args, setCount),
            {
                revalidateIfStale: false,
                revalidateOnFocus: false,
                revalidateOnReconnect: false,
            }
        ))
    }

    const paginatedData = data ? [].concat(...data) : []

    const isLoadingInitialData = !data && !error

    const isLoadingMore =
        isLoadingInitialData ||
        (size > 0 && data && isUndefined(data[size - 1]))

    const isEmpty = data?.[0]?.length === 0

    const isReachingEnd =
        isEmpty || (data && data[data.length - 1]?.length < pageSize)

    const isRefreshing = isValidating && data && data?.[0]?.length === size

    return {
        data: paginatedData,
        error,
        size,
        setSize,
        isReachingEnd,
        isLoadingInitialData,
        isLoadingMore,
        isRefreshing,
        mutate,
        count,
    }
}
